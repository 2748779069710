import { PageLogin } from "pages/login";
import { mainPageLoader, PageMain } from "pages/main";
import { newOrderPageLoader, PageNewOrder } from "pages/new-order";
import { orderPageLoader, PageOrder } from "pages/order";
import { ordersPageLoader, PageOrders } from "pages/orders";
import { outletsPageLoader, PageOutlets } from "pages/outlets";
import { PageProfile, profilePageLoader } from "pages/profile";
import {
  PageProtected,
  PageProtectedErrorElement,
  protectedPageLoader,
} from "pages/protected";
import { PageRoot, PageRootErrorElement } from "pages/root";
import { PageWelcome } from "pages/welcome";
import {
  createBrowserRouter,
  RouterProvider as BaseRouterProvider,
} from "react-router-dom";
import { Paths } from "shared/config";

export const RouterProvider = () => {
  const router = createBrowserRouter([
    {
      element: <PageRoot />,
      errorElement: <PageRootErrorElement />,
      path: Paths.Main,
      children: [
        {
          element: <PageLogin />,
          path: Paths.Login,
        },
        {
          element: <PageWelcome />,
          path: Paths.Welcome,
        },
        {
          element: <PageProtected />,
          errorElement: <PageProtectedErrorElement />,
          loader: protectedPageLoader,
          path: Paths.Main,
          children: [
            {
              element: <PageMain />,
              loader: mainPageLoader,
              path: Paths.Main,
              children: [
                {
                  element: <PageOrders />,
                  index: true,
                  loader: ordersPageLoader,
                },
              ],
            },
            {
              element: <PageNewOrder />,
              loader: newOrderPageLoader,
              path: Paths.NewOrder,
            },
            {
              element: <PageOrder />,
              loader: orderPageLoader,
              path: Paths.Order,
            },
            {
              element: <PageOutlets />,
              loader: outletsPageLoader,
              path: Paths.Outlets,
            },
            {
              element: <PageProfile />,
              loader: profilePageLoader,
              path: Paths.Profile,
            },
          ],
        },
      ],
    },
  ]);

  return <BaseRouterProvider router={router} />;
};
