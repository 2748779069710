// TODO: to config
const prefixInternational = "+60";
const prefixNational = "0";

export const parsePhoneNumber = (s: string) => {
  // Будет работать, пока в маске нет других разделителей, кроме пробелов
  const raw = s.replace(/\s/g, "");

  if (raw.startsWith(prefixInternational)) {
    return raw;
  }

  const startsWithPrefixNationalRegex = new RegExp(`^${prefixNational}`);

  if (raw.startsWith(prefixNational)) {
    return raw.replace(startsWithPrefixNationalRegex, prefixInternational);
  }

  return "";
};
