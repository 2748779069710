import dayjs from "dayjs";
import {
  format as formatDate,
  isToday,
  isYesterday,
} from "shared/lib/datetime";

export const getOrdersGroupTitle = (
  created: string | number | dayjs.Dayjs | Date | null | undefined,
) => {
  if (isToday(created)) {
    return "Today";
  }

  if (isYesterday(created)) {
    return "Yesterday";
  }

  return formatDate(created, "DD/MM/YY");
};
