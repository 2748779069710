import { CreateOrderForm } from "features/create-order";
import { useLoaderData } from "react-router-dom";
import { Container } from "shared/ui";

import { newOrderPageLoader } from "./loader";

export const PageNewOrder = () => {
  const { defaultTradeOutletId, tradeOutlets } = useLoaderData() as Awaited<
    ReturnType<typeof newOrderPageLoader>
  >;

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <CreateOrderForm
        defaultTradeOutletId={defaultTradeOutletId}
        tradeOutlets={tradeOutlets}
      />
    </Container>
  );
};
