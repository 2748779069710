import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type OrderStatus = bnplMerchants.GetOrdersManyStatus;

export type Order = {
  amount: bnplMerchants.GetOrdersMany["amount"];
  applicationId: bnplMerchants.GetOrdersMany["application_id"];
  archived: bnplMerchants.GetOrdersMany["archived"];
  calcId: bnplMerchants.GetOrdersMany["calc_id"];
  clientId: bnplMerchants.GetOrdersMany["client_id"];
  contractId: bnplMerchants.GetOrdersMany["contract_id"];
  created: bnplMerchants.GetOrdersMany["created"];
  id: bnplMerchants.GetOrdersMany["id"];
  link: bnplMerchants.GetOrdersMany["link"];
  offerId: bnplMerchants.GetOrdersMany["offer_id"];
  orderNumber: bnplMerchants.GetOrdersMany["order_number"];
  status: OrderStatus;
  tradeOutletId: bnplMerchants.GetOrdersMany["trade_outlet_id"];
  updated: bnplMerchants.GetOrdersMany["updated"];
};

export type GetOrdersParams = {
  archived?: bnplMerchants.GetApiMerchantsServiceOrdersParams["archived"];
  clientId?: bnplMerchants.GetApiMerchantsServiceOrdersParams["client_id"];
  id?: bnplMerchants.GetApiMerchantsServiceOrdersParams["id"];
  idIn?: bnplMerchants.GetApiMerchantsServiceOrdersParams["id_in"];
  limit?: bnplMerchants.GetApiMerchantsServiceOrdersParams["limit"];
  offset?: bnplMerchants.GetApiMerchantsServiceOrdersParams["offset"];
  orderBy?: bnplMerchants.GetApiMerchantsServiceOrdersParams["order_by"];
  orderNumber?: bnplMerchants.GetApiMerchantsServiceOrdersParams["order_number"];
  orderNumberIn?: bnplMerchants.GetApiMerchantsServiceOrdersParams["order_number_in"];
  statusIn?: bnplMerchants.GetApiMerchantsServiceOrdersParams["status_in"];
  tradeOutletId: bnplMerchants.GetApiMerchantsServiceOrdersParams["trade_outlet_id"];
};

export type GetOrdersResponseData = {
  items: Order[];
};

export const getOrders = async (
  params: GetOrdersParams,
): Promise<GetOrdersResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceEmployeeOrders({
    archived: params.archived,
    client_id: params.clientId,
    id: params.id,
    id_in: params.idIn,
    limit: params.limit,
    offset: params.offset,
    order_by: params.orderBy,
    order_number: params.orderNumber,
    order_number_in: params.orderNumberIn,
    status_in: params.statusIn,
    // @ts-ignore
    trade_outlet_id: params.tradeOutletId,
  });

  return {
    items:
      response.data.data?.items?.map((item) => ({
        amount: item.amount,
        applicationId: item.application_id,
        archived: item.archived,
        calcId: item.calc_id,
        clientId: item.client_id,
        contractId: item.contract_id,
        created: item.created,
        id: item.id,
        link: item.link,
        offerId: item.offer_id,
        orderNumber: item.order_number,
        status: item.status,
        tradeOutletId: item.trade_outlet_id,
        updated: item.updated,
      })) || [],
  };
};
