import dayjs from "dayjs";
import isYesterdayPlugin from "dayjs/plugin/isYesterday";

dayjs.extend(isYesterdayPlugin);

export const isYesterday = (
  date?: string | number | dayjs.Dayjs | Date | null | undefined,
) => {
  return dayjs(date).isYesterday();
};
