import { OrderStatus } from "shared/api";

export type OrderPublicStatus =
  | "CANCELLED"
  | "COMPLETED"
  | "DRAFT"
  | "REFUNDED";

const publicStatusByOrderStatus: Record<OrderStatus, OrderPublicStatus> = {
  ACTIVE: "COMPLETED",
  CANCELLED: "CANCELLED",
  CONFIRMED: "DRAFT",
  PAYMENT_FAILED: "DRAFT",
  PENDING: "DRAFT",
  PROCESSING: "DRAFT",
  REFUNDED: "REFUNDED",
  // TODO: не должны приходить, нужно подумать, что делать в таком случае
  EXPIRED: "CANCELLED",
  REJECTED: "CANCELLED",
  REPAID: "COMPLETED",
};

export const getOrderPublicStatus = (status: OrderStatus) => {
  return publicStatusByOrderStatus[status];
};
