import { useRouteError } from "react-router-dom";
import { Container, Typography } from "shared/ui";

export const PageRootErrorElement = () => {
  const error = useRouteError();

  const getErrorMessage = () => {
    if (error instanceof Response) {
      return error.statusText;
    }

    if (error instanceof Error) {
      return error.message;
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        p: 2,
      }}
    >
      <Typography align="center" mb={1} variant="titleS">
        Something went wrong
      </Typography>
      <Typography align="center" color="common.outline">
        {getErrorMessage()}
      </Typography>
    </Container>
  );
};
