import { useLogout } from "features/logout";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Paths } from "shared/config";
import {
  ActionButton,
  Box,
  Container,
  FormatPhone,
  IconCheck,
  IconChevronRight,
  PageTitle,
  Stack,
  Typography,
} from "shared/ui";

import { profilePageLoader } from "./loader";

type RowProps = {
  name: string;
  value: React.ReactNode;
};

const Row = ({ name, value }: RowProps) => {
  return (
    <ActionButton
      description={value as string}
      onClick={() => {}}
      text={name}
      endIcon={<IconCheck color="primary" />}
    />
  );
};

export const PageProfile = () => {
  const { employee } = useLoaderData() as Awaited<
    ReturnType<typeof profilePageLoader>
  >;
  const navigate = useNavigate();
  const logout = useLogout();

  const handleLogoutActionButtonClick = () => {
    logout.mutate(undefined, {
      onSettled: () => {
        navigate(Paths.Welcome);
      },
    });
  };

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <PageTitle title="Menu" />
      <Box mb={3}>
        <Typography mb={2} variant="bigTextMedium">
          Personal info
        </Typography>
        <Stack spacing={1}>
          <Row name="Full name" value={employee.name} />
          {/* TODO: country code and replace to config */}
          <Row
            name="Phone"
            value={
              <FormatPhone
                countryCode="MY"
                value={employee.phone.replace(/^\+60/, "")}
              />
            }
          />
        </Stack>
      </Box>
      <Box>
        <Typography mb={2} variant="bigTextMedium">
          Account
        </Typography>
        <ActionButton
          endIcon={<IconChevronRight />}
          onClick={handleLogoutActionButtonClick}
          text="Logout"
        />
      </Box>
    </Container>
  );
};
