import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetTradeOutletsResponseData = {
  agreementId: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["agreement_id"];
  id: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["id"];
  isOnline: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["is_online"];
  location: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["location"];
  locationGeo: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["location_geo"];
  merchantId: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["merchant_id"];
  name: bnplMerchants.TradeOutletExcludeArchivedCreatedUpdated["name"];
}[];

export const getTradeOutlets =
  async (): Promise<GetTradeOutletsResponseData> => {
    const response =
      await bnplMerchants.getApiMerchantsServiceEmployeeTradeOutlets();

    return (
      response.data.data?.map((item) => {
        return {
          agreementId: item.agreement_id,
          id: item.id,
          isOnline: item.is_online,
          location: item.location,
          locationGeo: item.location_geo,
          merchantId: item.merchant_id,
          name: item.name,
        };
      }) || []
    );
  };
