import axios from "axios";
import { addAuthTokenHeader } from "shared/api/middleware/add-auth-token-header";
import { refreshTokenInterceptor } from "shared/api/middleware/refresh-token";
import { API_URL } from "shared/config";

axios.interceptors.request.use(addAuthTokenHeader);

axios.interceptors.response.use((response) => {
  return response;
}, refreshTokenInterceptor);

const init = () => {
  axios.defaults.baseURL = API_URL;
  axios.defaults.paramsSerializer = {
    indexes: null,
  };
};

export const Axios = {
  init,
};

export { axios };
