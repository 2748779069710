import { LoaderFunctionArgs } from "react-router-dom";
import { getOrder, getTradeOutlet } from "shared/api";

export const orderPageLoader = async ({ params }: LoaderFunctionArgs) => {
  if (!params.id) {
    throw new Error("No id"); // TODO: refactor
  }

  const order = await getOrder(params.id);

  const outlet = await getTradeOutlet(order.tradeOutletId);

  return {
    order,
    outlet,
  };
};
