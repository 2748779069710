import dayjs from "dayjs";
import isTodayPlugin from "dayjs/plugin/isToday";

dayjs.extend(isTodayPlugin);

export const isToday = (
  date?: string | number | dayjs.Dayjs | Date | null | undefined,
) => {
  return dayjs(date).isToday();
};
