import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetTradeOutletResponseData = {
  agreementId: bnplMerchants.GetExtendedTradeOutlet["agreement_id"];
  archived: bnplMerchants.GetExtendedTradeOutlet["archived"];
  created: bnplMerchants.GetExtendedTradeOutlet["created"];
  id: bnplMerchants.GetExtendedTradeOutlet["id"];
  isOnline: bnplMerchants.GetExtendedTradeOutlet["is_online"];
  location: bnplMerchants.GetExtendedTradeOutlet["location"];
  locationGeo: bnplMerchants.GetExtendedTradeOutlet["location_geo"];
  merchantId: bnplMerchants.GetExtendedTradeOutlet["merchant_id"];
  name: bnplMerchants.GetExtendedTradeOutlet["name"];
  newOrderLinkQr: bnplMerchants.GetExtendedTradeOutlet["new_order_link_qr"];
  updated: bnplMerchants.GetExtendedTradeOutlet["updated"];
};

export const getTradeOutlet = async (
  id: string,
): Promise<GetTradeOutletResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceEmployeeTradeOutletsId(id);

  if (!response.data.data) {
    throw new Error("getTradeOutlet: missing data"); // TODO: Sentry
  }

  return {
    agreementId: response.data.data.agreement_id,
    archived: response.data.data.archived,
    created: response.data.data.created,
    id: response.data.data.id,
    isOnline: response.data.data.is_online,
    location: response.data.data.location,
    locationGeo: response.data.data.location_geo,
    merchantId: response.data.data.merchant_id,
    name: response.data.data.name,
    newOrderLinkQr: response.data.data.new_order_link_qr,
    updated: response.data.data.updated,
  };
};
