import "./styles";

import * as Sentry from "@sentry/react";
import { SENTRY_DSN } from "shared/config";

import {
  QueryClientProvider,
  RouterProvider,
  SessionProvider,
  SnackbarProvider,
  ThemeProvider,
} from "./providers";

Sentry.init({
  dsn: SENTRY_DSN,
});

export const App = () => {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <SessionProvider>
            <RouterProvider />
          </SessionProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
