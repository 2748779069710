import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type CreateOrderRequestParams = {
  amount: bnplMerchants.CreateOrder["amount"];
  applicationId?: bnplMerchants.CreateOrder["application_id"];
  calcId?: bnplMerchants.CreateOrder["calc_id"];
  clientId?: bnplMerchants.CreateOrder["client_id"];
  contractId?: bnplMerchants.CreateOrder["contract_id"];
  offerId?: bnplMerchants.CreateOrder["offer_id"];
  orderItems: bnplMerchants.CreateOrder["order_items"];
  referenceNumber?: bnplMerchants.CreateOrder["reference_number"];
  tradeOutletId: bnplMerchants.CreateOrder["trade_outlet_id"];
};

export type CreateOrderResponseData = {
  amount: bnplMerchants.CreateOrderResp["amount"];
  applicationId: bnplMerchants.CreateOrderResp["application_id"];
  archived: bnplMerchants.CreateOrderResp["archived"];
  calcId: bnplMerchants.CreateOrderResp["calc_id"];
  clientId: bnplMerchants.CreateOrderResp["client_id"];
  contractId: bnplMerchants.CreateOrderResp["contract_id"];
  created: bnplMerchants.CreateOrderResp["created"];
  id: bnplMerchants.CreateOrderResp["id"];
  link: bnplMerchants.CreateOrderResp["link"];
  offerId: bnplMerchants.CreateOrderResp["offer_id"];
  orderItems: bnplMerchants.CreateOrderResp["order_items"];
  orderNumber: bnplMerchants.CreateOrderResp["order_number"];
  referenceNumber: bnplMerchants.CreateOrderResp["reference_number"];
  status: bnplMerchants.CreateOrderResp["status"];
  tradeOutletId: bnplMerchants.CreateOrderResp["trade_outlet_id"];
  updated: bnplMerchants.CreateOrderResp["updated"];
};

export const createOrder = async (
  params: CreateOrderRequestParams,
): Promise<CreateOrderResponseData> => {
  const response = await bnplMerchants.postApiMerchantsServiceEmployeeOrders({
    amount: params.amount,
    application_id: params.applicationId,
    calc_id: params.calcId,
    client_id: params.clientId,
    contract_id: params.contractId,
    offer_id: params.offerId,
    order_items: params.orderItems,
    reference_number: params.referenceNumber,
    trade_outlet_id: params.tradeOutletId,
  });

  if (!response.data.data) {
    throw new Error("createOrder: missing data");
  }

  return {
    amount: response.data.data?.amount,
    applicationId: response.data.data.application_id,
    archived: response.data.data.archived,
    calcId: response.data.data.calc_id,
    clientId: response.data.data.client_id,
    contractId: response.data.data.contract_id,
    created: response.data.data.created,
    id: response.data.data.id,
    link: response.data.data.link,
    offerId: response.data.data.offer_id,
    orderItems: response.data.data.order_items,
    orderNumber: response.data.data.order_number,
    referenceNumber: response.data.data.reference_number,
    status: response.data.data.status,
    tradeOutletId: response.data.data.trade_outlet_id,
    updated: response.data.data.updated,
  };
};
