import { OrderStatus } from "shared/api";
import { format as formatCurrency } from "shared/lib/currency";
import { format as formatDate } from "shared/lib/datetime";
import {
  Box,
  Card,
  CardActionArea,
  IconBox2,
  IconCheck,
  IconClose,
  IconUndo,
  Stack,
  Typography,
} from "shared/ui";

import { getOrderPublicStatus } from "../../lib";

type OrderStatusIndicatorProps = {
  status: OrderStatus;
};

const OrderStatusIndicator = ({ status }: OrderStatusIndicatorProps) => {
  const publicStatus = getOrderPublicStatus(status);

  const stylesByPublicStatus = {
    CANCELLED: {
      icon: (
        <IconClose
          color="error"
          sx={{
            display: "block",
          }}
        />
      ),
      background: "error.95",
    },
    COMPLETED: {
      background: "success.95",
      icon: (
        <IconCheck
          color="success"
          sx={{
            display: "block",
          }}
        />
      ),
    },
    DRAFT: {
      icon: (
        <IconBox2
          sx={{
            color: "blueGray.40",
            display: "block",
          }}
        />
      ),
      background: "blueGray.95",
    },
    REFUNDED: {
      background: "warning.95",
      icon: (
        <IconUndo
          color="warning"
          sx={{
            display: "block",
          }}
        />
      ),
    },
  };

  const styles = stylesByPublicStatus[publicStatus];

  return (
    <Box bgcolor={styles.background} borderRadius="6px" p={0.5}>
      {styles.icon}
    </Box>
  );
};

export type OrderListItemProps = {
  amount: number;
  onClick: () => void;
  orderNumber: string;
  status: OrderStatus;
  time: Date | undefined;
};

export const OrderListItem = ({
  amount,
  onClick,
  orderNumber,
  status,
  time,
}: OrderListItemProps) => {
  return (
    <Card>
      <CardActionArea
        onClick={onClick}
        sx={{
          p: 2,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <OrderStatusIndicator status={status} />
          <Box flex={1}>
            <Typography color="neutral.20" variant="mediumTextSemiBold">
              #{orderNumber}
            </Typography>
            <Typography color="neutral.50" variant="smallTextRegular" mt={0.5}>
              {formatDate(time, "DD/MM/YYYY, HH:mm")}
            </Typography>
          </Box>
          <Typography
            alignSelf="flex-start"
            color="neutral.20"
            variant="mediumTextSemiBold"
          >
            {formatCurrency(amount)}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
