import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type EmployeeRole = bnplMerchants.EmployeeManyRole;

export type GetEmployeeByTokenResponseData = {
  archived: bnplMerchants.EmployeeMany["archived"];
  created: bnplMerchants.EmployeeMany["created"];
  email: bnplMerchants.EmployeeMany["email"];
  id: bnplMerchants.EmployeeMany["id"];
  merchantId: bnplMerchants.EmployeeMany["merchant_id"];
  name: bnplMerchants.EmployeeMany["name"];
  phone: bnplMerchants.EmployeeMany["phone"];
  role: EmployeeRole;
  updated: bnplMerchants.EmployeeMany["updated"];
};

export const getEmployeeByToken =
  async (): Promise<GetEmployeeByTokenResponseData> => {
    const response = await bnplMerchants.getApiMerchantsServiceEmployee();

    if (!response.data.data) {
      throw new Error("getEmployeeByToken: missing data");
    }

    return {
      archived: response.data.data?.archived,
      created: response.data.data?.created,
      email: response.data.data.email,
      id: response.data.data.id,
      merchantId: response.data.data.merchant_id,
      name: response.data.data.name,
      phone: response.data.data.phone,
      role: response.data.data.role,
      updated: response.data.data.updated,
    };
  };
