import { queryClient } from "app/providers";
import { getSession } from "entities/sessions";
import { redirect } from "react-router-dom";
import { getTradeOutlets } from "shared/api";
import { Paths } from "shared/config";

export const protectedPageLoader = async () => {
  const { accessToken } = getSession();

  if (!accessToken) {
    return redirect(Paths.Welcome);
  }

  const outlets = await queryClient.fetchQuery({
    queryKey: ["outlets"],
    queryFn: () => {
      return getTradeOutlets();
    },
    staleTime: 10000,
  });

  if (outlets.length === 0) {
    throw new Error("No outlets");
  }

  return null;
};
