// TODO: move to ui-kit
import {
  Box,
  Dialog,
  Drawer,
  IconButton,
  IconClose,
  Show,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mychili/ui-web";

type CloseIconButtonProps = {
  onClose: () => void;
  show: boolean;
  dataTest?: string;
};

const CloseIconButton = ({ show, onClose, dataTest }: CloseIconButtonProps) => (
  <Show when={show}>
    <IconButton
      onClick={onClose}
      data-test={dataTest}
      sx={{
        position: "absolute",
        height: 24,
        width: 24,
        top: 12,
        right: 16,
        color: "common.black",
        cursor: "pointer",
      }}
    >
      <IconClose />
    </IconButton>
  </Show>
);

const ActionSheetDragIndicator = () => {
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      py={1}
      display="flex"
      justifyContent="center"
    >
      <Box width={32} height={4} bgcolor="#d1d4db" borderRadius={16} />
    </Box>
  );
};

export type ActionSheetProps = {
  children?: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  isSwipeable?: boolean;
  hasCloseIcon?: boolean;
  buttonCloseTestId?: string;
};

export const ActionSheet = ({ children, ...rest }: ActionSheetProps) => {
  return <Container {...rest}>{children}</Container>;
};

const Container = ({
  children,
  isOpen,
  isSwipeable = true,
  hasCloseIcon = false,
  onClose,
  onOpen,
  buttonCloseTestId,
}: ActionSheetProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const backdropProps = { sx: { background: "rgba(17, 24, 40, 0.8)" } };
  const paperProps = {
    sx: {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      maxHeight: "calc(100% - 8px)",
    },
  };

  if (isDesktop) {
    return (
      <Dialog
        BackdropProps={backdropProps}
        fullWidth={true}
        onClose={onClose}
        open={isOpen}
      >
        <CloseIconButton
          show={hasCloseIcon}
          onClose={onClose}
          dataTest={buttonCloseTestId}
        />
        {children}
      </Dialog>
    );
  }

  if (isSwipeable) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        BackdropProps={backdropProps}
        disableSwipeToOpen={true}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        PaperProps={paperProps}
      >
        <ActionSheetDragIndicator />
        <Box>{children}</Box>
      </SwipeableDrawer>
    );
  }

  return (
    <Drawer
      anchor="bottom"
      BackdropProps={{ sx: { background: "rgba(17, 24, 40, 0.8)" } }}
      onClose={onClose}
      open={isOpen}
      PaperProps={paperProps}
    >
      <Box>
        <CloseIconButton
          show={hasCloseIcon}
          onClose={onClose}
          dataTest={buttonCloseTestId}
        />
        {children}
      </Box>
    </Drawer>
  );
};
