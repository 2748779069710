export const enum Paths {
  Login = "/login",
  Main = "/",
  NewOrder = "/orders/new",
  Order = "/orders/:id",
  Outlets = "/outlets",
  Profile = "/profile",
  Welcome = "/welcome",
}

export const paths = {
  login: () => "/login",
  main: () => "/",
  newOrder: () => {
    return "/orders/new";
  },
  order: (orderId: string) => `/orders/${orderId}`,
  outlets: () => {
    return "/outlets";
  },
  welcome: () => "/welcome",
};
