import dayjs from "dayjs";
import { OrderListItem, useOrders } from "entities/orders";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetOrdersParams, Order } from "shared/api";
import { paths } from "shared/config";
import { useDebounce } from "shared/lib/common";
import { groupBy } from "shared/lib/lodash";
import { Box, Card, IconInfo, Input, Stack, Typography } from "shared/ui";

import { getOrdersGroupTitle } from "../../lib";

export type OrdersListProps = {
  tradeOutletId: GetOrdersParams["tradeOutletId"];
};

export const OrdersList = ({ tradeOutletId }: OrdersListProps) => {
  const [searchOrderNumber, setSearchOrderNumber] = useState<string>();
  const searchOrderNumberDebounced = useDebounce(searchOrderNumber, 500);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOrderNumber(e.currentTarget.value || undefined);
  };

  return (
    <React.Fragment>
      <Typography mb={3} variant="headlineH3">
        Orders
      </Typography>
      <Input
        inputProps={{
          inputMode: "numeric",
        }}
        onChange={handleInputChange}
        placeholder="Search by order number"
        size="small"
        sx={{
          mb: 3,
        }}
      />
      <OrdersListContent
        orderNumber={searchOrderNumberDebounced}
        tradeOutletId={tradeOutletId}
      />
    </React.Fragment>
  );
};

type OrdersListContentProps = {
  orderNumber?: string;
  tradeOutletId?: string;
};

const OrdersListContent = ({
  orderNumber,
  tradeOutletId,
}: OrdersListContentProps) => {
  const { data, isError, isFetching, isPending } = useOrders({
    orderBy: "-created",
    tradeOutletId,
    orderNumber,
  });

  if (isPending) {
    return "Pending";
  }

  if (isFetching) {
    return "Fetching";
  }

  if (isError) {
    return "Error";
  }

  return <OrdersListRenderer orders={data.items} />;
};

export type OrdersListRendererProps = {
  orders: Order[];
};

export const OrdersListRenderer = ({ orders }: OrdersListRendererProps) => {
  const navigate = useNavigate();

  if (orders.length === 0) {
    return (
      <Card
        sx={{
          p: 2,
        }}
      >
        <Stack alignItems="flex-start" direction="row" spacing={2}>
          <Box
            bgcolor="#e1f5fe"
            display="inline-flex"
            p={0.5}
            borderRadius="6px"
          >
            <IconInfo color="info" />
          </Box>
          <Box>
            <Typography mb={0.5} variant="mediumTextMedium">
              Create new order
            </Typography>
            <Typography color="neutral.50" variant="regularTextRegular">
              There are no orders yet. To make new one press the button down
              below «Create order»
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  }

  const handleOrderClick = (orderId?: string) => () => {
    if (!orderId) {
      return; // TODO: Sentry? what to do
    }

    navigate(paths.order(orderId));
  };

  const grouped = groupBy(orders, (item) => {
    return dayjs(item.created).startOf("day").format();
  });

  return (
    <Stack spacing={3}>
      {Object.entries(grouped).map((entry) => {
        const [created, items] = entry;

        return (
          <Box key={created}>
            <Typography mb={2} variant="bigTextMedium">
              {getOrdersGroupTitle(created)}
            </Typography>
            <Stack spacing={1}>
              {items.map((order) => {
                return (
                  <OrderListItem
                    amount={order.amount}
                    key={order.id}
                    onClick={handleOrderClick(order.id)}
                    orderNumber={order.orderNumber}
                    status={order.status}
                    // TODO: fix typing
                    time={order.created as unknown as Date}
                  />
                );
              })}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};
