import { queryClient } from "app/providers";
import { getTradeOutlets } from "shared/api";

export const mainPageLoader = async () => {
  const outlets = await queryClient.fetchQuery({
    queryKey: ["outlets"],
    queryFn: () => {
      return getTradeOutlets();
    },
    staleTime: 10000,
  });

  let defaultTradeOutletId = outlets[0].id;

  // @TODO Move to storage key/hook
  const currentTradeOutletId = localStorage.getItem("currentTradeOutletId");

  const isCurrentTradeOutletExists =
    currentTradeOutletId && outlets.some((o) => o.id === currentTradeOutletId);

  if (isCurrentTradeOutletExists) {
    defaultTradeOutletId = currentTradeOutletId;
  } else {
    localStorage.setItem("currentTradeOutletId", String(outlets[0].id));
  }

  return {
    defaultTradeOutletId,
    outlets,
  };
};
