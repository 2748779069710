import { QueryClientProvider as Provider } from "@tanstack/react-query";

import { queryClient } from "./query-client";

export type QueryClientProviderProps = {
  children: React.ReactNode;
};

export const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  return <Provider client={queryClient}>{children}</Provider>;
};
