import { getOrderPublicStatus } from "entities/orders";
import { FormSendOrderLink } from "features/send-order-link";
import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getImageSrcFromBase64 } from "shared/lib/common";
import { format as formatCurrency } from "shared/lib/currency";
import { format as formatDate } from "shared/lib/datetime";
import { capitalize } from "shared/lib/lodash";
import {
  ActionSheet,
  Box,
  Button,
  Card,
  Container,
  Divider,
  IconShare,
  Label,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import { orderPageLoader } from "./loader";

type RowProps = {
  title: string;
  value: React.ReactNode;
};

const Row = ({ title, value }: RowProps) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography color="neutral.20" variant="regularTextRegular">
        {title}
      </Typography>
      {typeof value === "string" ? (
        <Typography color="neutral.20" variant="regularTextRegular">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
};

export const PageOrder = () => {
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);

  const navigate = useNavigate();

  const { order, outlet } = useLoaderData() as Awaited<
    ReturnType<typeof orderPageLoader>
  >;

  const publicStatus = getOrderPublicStatus(order.status);

  const chipColorByPublicStatus = {
    CANCELLED: "error",
    COMPLETED: "success",
    DRAFT: "neutral",
    REFUNDED: "warning",
  } as const;

  const handleSendLinkButtonClick = () => {
    setOpenSendEmailDialog(true);
  };

  const handleSendLinkDialogClose = () => {
    setOpenSendEmailDialog(false);
  };

  return (
    <React.Fragment>
      <Container
        sx={{
          py: 2,
        }}
      >
        <Show when={publicStatus === "DRAFT" && order.linkQr}>
          {(linkQr) => (
            <Card
              sx={{
                mb: 2,
                p: 2,
              }}
            >
              <Typography align="center" variant="regularTextMedium">
                Order #{order.orderNumber}
              </Typography>
              <Typography
                color="neutral.70"
                mb={2}
                textAlign="center"
                variant="bodyMedium"
              >
                Show this QR to customer
              </Typography>
              <React.Fragment>
                {/* @TODO From theme */}
                <Box sx={{ background: "#F7F7F8" }} p={2}>
                  <Box
                    component="img"
                    display="block"
                    maxWidth={200}
                    mx="auto"
                    src={getImageSrcFromBase64(linkQr)}
                    textAlign="center"
                    width="100%"
                  />
                </Box>
                <Button
                  fullWidth={true}
                  onClick={handleSendLinkButtonClick}
                  size="medium"
                  startIcon={<IconShare />}
                  variant="link"
                >
                  Send QR-Code
                </Button>
              </React.Fragment>
            </Card>
          )}
        </Show>
        <Card
          sx={{
            p: 2,
          }}
        >
          <Typography color="neutral.20" mb={0.5} variant="mediumTextSemiBold">
            {outlet.name}
          </Typography>
          <Typography color="neutral.50" mb={2} variant="smallTextRegular">
            {outlet.locationGeo}
          </Typography>
          <Stack spacing={1}>
            <Row title="Order number" value={order.orderNumber} />
            <Row
              title="Created"
              value={
                <Typography color="neutral.50" variant="regularTextRegular">
                  {formatDate(order.created, "DD/MM/YYYY, HH:mm")}
                </Typography>
              }
            />
            <Row
              title="Order status"
              value={
                <Label
                  color={chipColorByPublicStatus[publicStatus]}
                  size="M"
                  showIndicator
                >
                  {capitalize(publicStatus)}
                </Label>
              }
            />
          </Stack>
          <Divider
            sx={{
              my: 2,
            }}
          />
          <Row
            title="Order amount"
            value={
              <Typography color="neutral.20" variant="bigTextSemiBold">
                {formatCurrency(order.amount)}
              </Typography>
            }
          />
        </Card>
        <Box
          mt={{
            xs: "76px",
            md: 2,
          }}
        >
          <Box
            bottom={16}
            left={16}
            position={{
              xs: "fixed",
              md: "static",
            }}
            right={16}
          >
            <Button
              fullWidth={true}
              onClick={() => {
                navigate(-1);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Container>
      <ActionSheet
        onOpen={() => {}}
        onClose={handleSendLinkDialogClose}
        isOpen={openSendEmailDialog}
      >
        <Box p={2}>
          <FormSendOrderLink
            onCancel={handleSendLinkDialogClose}
            onSuccess={handleSendLinkDialogClose}
            orderId={order.id}
          />
        </Box>
      </ActionSheet>
    </React.Fragment>
  );
};
