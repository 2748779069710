import { queryClient } from "app/providers";
import { getTradeOutlets } from "shared/api";

export const outletsPageLoader = async () => {
  const outlets = await queryClient.fetchQuery({
    queryKey: ["outlets"],
    queryFn: () => {
      return getTradeOutlets();
    },
    staleTime: 10000,
  });

  const defaultTradeOutletId =
    localStorage.getItem("currentTradeOutletId") || outlets[0].id;

  return {
    defaultTradeOutletId,
    outlets,
  };
};
