import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import { Form, useLoaderData, useSubmit } from "react-router-dom";
import { Input, Typography } from "shared/ui";
import { OrdersListRenderer } from "widgets/orders-list";

import { ordersPageLoader } from "./loader";

export const PageOrders = () => {
  const { orders, orderNumber } = useLoaderData() as Awaited<
    ReturnType<typeof ordersPageLoader>
  >;

  const inputRef = useRef<HTMLInputElement>();

  const submit = useSubmit();
  const debouncedSubmit = debounce(submit, 500);

  useEffect(() => {
    if (orderNumber && inputRef.current) {
      inputRef.current.value = orderNumber;
    }
  }, [orderNumber]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSubmit(e.currentTarget.form);
  };

  return (
    <React.Fragment>
      <Typography mb={3} variant="headlineH3">
        Orders
      </Typography>
      <Form>
        <Input
          id="orderNumber"
          name="orderNumber"
          inputProps={{
            inputMode: "numeric",
          }}
          onChange={handleChange}
          placeholder="Search by order number"
          size="small"
          sx={{
            mb: 3,
          }}
          defaultValue={orderNumber}
        />
      </Form>
      <OrdersListRenderer orders={orders.items} />
    </React.Fragment>
  );
};
