import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type SendOrderLinkParams = {
  email?: bnplMerchants.SendOrderLink["email"];
  orderId: string;
  phone?: bnplMerchants.SendOrderLink["phone"];
};

export const sendOrderLink = async ({
  orderId,
  ...params
}: SendOrderLinkParams): Promise<boolean> => {
  const response =
    await bnplMerchants.postApiMerchantsServiceEmployeeOrdersIdSendLink(
      orderId,
      params,
    );

  if (!response.data.data?.success) {
    throw new Error("sendOrderLink -> no success response");
  }

  return response.data.data?.success;
};
