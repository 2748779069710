import { aha, CssBaseline, ThemeProvider as Provider } from "@mychili/ui-web";

export type ThemeProviderProps = { children: React.ReactNode };

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <Provider theme={aha}>
      <CssBaseline />
      {children}
    </Provider>
  );
};
